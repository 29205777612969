/********** This CSS style makes it difficult or impossible to see the dotted link focus outline ************/
/* /core/libraries/slickSlider/assets/1.8.1/slick.css */
.inside-row .slick-list:focus {
	outline: 1px solid #0000ff !important;
}


/* /core/libraries/select2/assets/select2.css */
.inside-row .select2-offscreen,
.inside-row .select2-offscreen:focus {
	outline: 1px solid #0000ff !important;
	border: 1px solid #fff !important;
}
